<template>
  <el-select
    v-model="select_country"
    @change="CountryChangeChild"
    placeholder="点击选择【国家/country】"
    multiple
    remote
    clearable
    style="margin-right: 10px; width: 240px"
  >
    <div style="float: left; overflow: hidden; height: 200px; overflow-y: auto; width: 200px">
      <el-input
        style="width: 90%; margin-left: 5%; margin-bottom: 5px"
        v-model="inputCountry"
        @keyup.enter.native="enterCountry"
        clearable
      ></el-input>
      <button @click="selectAllcountry" style="height: 24px; width: 70px; margin-left: 13px; margin-bottom: 10px">全选</button>
      <button style="height: 24px; width: 70px; margin-left: 20px" @click="clearAllcountry">清空</button>
      <el-option v-for="item in country_list" :key="item.ISO2" :label="item.label" :value="item.ISO2"></el-option>
      <el-option v-if="country_list == ''" label="无相关内容" value="无相关内容" :disabled="true"></el-option>
    </div>
    <div
      style="float: left; overflow: hidden; height: 200px; overflow-y: auto; padding: 10px; width: 160px; border-left: 1px #dee1e7 solid"
    >
      <div style="height: 30px" v-for="item in select_country" :key="item">
        <el-tag closable @close="handleCloseCountry(item)">{{ item }}</el-tag>
      </div>
    </div>
  </el-select>
</template>

<script>
export default {
  watch: {
    inputCountry() {
      var newlist4 = [];
      this.country_list.filter((item) => {
        if (item.label.toUpperCase().includes(this.inputCountry.toUpperCase())) {
          newlist4.push(item);
        }
      });
      // 优化防止二次请求
      this.country_list = newlist4;
      if (this.inputCountry == '') {
        // 调用父组件的方法
        // this.$parent.getCountry();
        this.country_list = this.originalList;
      }
    }
  },
  methods: {
    // 已选择的值会发送给父组件，让父组件在调用请求函数来去请求下一个选择框
    CountryChangeChild() {
      // 父组件可以使用@CountryChange接受这个改变信号，或者这个值
      this.$emit('CountryChange', this.select_country);
      // console.log('发送')
    },
    // 这个好像只会在搜索的时候用键盘enter才会响应的函数，暂不确定有什么用
    enterCountry() {
      if (this.select_country.includes(this.country_list[0])) {
        console.log('已选中');
      }
      //暂定给予的数据为取ISO2 原：this.select_country.push(this.country_list[0]);
      else this.select_country.push(this.country_list[0]['ISO2']);
      this.CountryChangeChild();
    },
    // 全选
    selectAllcountry() {
      this.select_country = this.country_list;
      // 全选后调用CountryChange
      this.CountryChangeChild();
    },
    // 清空
    clearAllcountry() {
      this.select_country = [];
      this.checked_country = false;
      this.CountryChangeChild();
    },
    // 右侧栏取消选择
    handleCloseCountry(tag) {
      this.select_country.splice(this.select_country.indexOf(tag), 1);
      this.CountryChangeChild();
    }
  },
  data() {
    return {
      inputCountry: '',
      country_list: [],
      checked_ad_source: false,
      select_country: [],
      // 保留初始值
      originalList: []
    };
  }
};
</script>
