<template>
  <el-row :gutter="40" class="panel-group">
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col" :key="index" v-for="(item,index) in cardData">
      <div class="card-panel">
        <div class="card-panel-icon-wrapper" v-bind:style="setStyle(item.color)">
          <svg-icon :icon-class="item.icon" class-name="card-panel-icon" />
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            {{item.name}}
          </div>
          <div style="text-align:center" v-if="item.color!=''">
            <count-to :start-val="0" :end-val="item.data" :duration="2600"  class="card-panel-num" />
            <span v-if="item.name==='点击至激活'">%</span>
          </div>
          <div style="text-align:center" v-else>
            <div class="card-panel-num"><i class="el-icon-loading" /></div>
          </div>
        </div>
      </div>
    </el-col>
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col" :key="item.name" v-for="item in cardDataCh">
      <div class="card-panel">
        <div class="card-panel-icon-wrapper" v-bind:style="setStyle(item.color)">
          <svg-icon :icon-class="item.icon" class-name="card-panel-icon" />
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            {{item.name}}
          </div>
          <div style="text-align:center" v-if="item.color!=''">
            <count-to :start-val="0" :end-val="item.data" :duration="2600"  class="card-panel-num" />
            <span v-if="item.name==='点击至激活'">%</span>
          </div>
          <div style="text-align:center" v-else>
            <div class="card-panel-num"><i class="el-icon-loading" /></div>
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import CountTo from 'vue-count-to'

export default {
  props: {
    cardData: {
      type: Array,
      required: true
    },
    cardDataCh:{
      type: Array,
      required: true
    }
  },
  components: {
    CountTo
  },
  methods: {
    handleSetLineChartData(type) {
      this.$emit('handleSetLineChartData', type)
    },
    setStyle(str){     // 设置绑定 style 方法
        if(str == "1"){
            return 'color:#40c9c6'
        }else if(str == "2"){
            return 'color:#36a3f7'
        }else if(str == "3"){
            return 'color:#f4516c'
        }else if(str == "4"){
            return 'color:#34bfa3'
        }else{
            return 'color:#34bfa3'
        }
    },
  }
}
</script>

<style lang="scss" scoped>
.panel-group {
  margin-top: 18px;

  .card-panel-col {
    margin-bottom: 32px;
  }

  .card-panel {
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);

    &:hover {
      .card-panel-icon-wrapper {
        color: #fff;
      }

      .icon-people {
        background: #40c9c6;
      }

      .icon-message {
        background: #36a3f7;
      }

      .icon-money {
        background: #f4516c;
      }

      .icon-shopping {
        background: #34bfa3
      }
    }

    .icon-people {
      color: #40c9c6;
    }

    .icon-message {
      color: #36a3f7;
    }

    .icon-money {
      color: #f4516c;
    }

    .icon-shopping {
      color: #34bfa3
    }

    .card-panel-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
      padding: 16px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }

    .card-panel-icon {
      float: left;
      font-size: 48px;
    }

    .card-panel-description {
      // float: right;
      font-weight: bold;
      margin: 26px;
      margin-left: 0px;

      .card-panel-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        margin-bottom: 12px;
        text-align: center;
      }

      .card-panel-num {
        font-size: 20px;
      }
    }
  }
}

@media (max-width:550px) {
  .card-panel-description {
    display: none;
  }

  .card-panel-icon-wrapper {
    float: none !important;
    width: 100%;
    height: 100%;
    margin: 0 !important;

    .svg-icon {
      display: block;
      margin: 14px auto !important;
      float: none !important;
    }
  }
}
</style>
