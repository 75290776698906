<template>
  <div>
    <div v-if="user_group.indexOf('测试组') == -1 && user_group.indexOf('市场组') == -1 && user_group.indexOf('产品组') == -1">
      <cw403></cw403>
    </div>
    <div v-if="user_group.indexOf('测试组') > -1 || user_group.indexOf('市场组') > -1 || user_group.indexOf('产品组') > -1">
      <div class="dashboard-editor-container">

        <div class="container">
          <div class="options-bar">
            <date-picker
              style="margin-right: 10px;width:240px" 
              v-model="date"
            >
            </date-picker>
              <el-select v-model="Project" clearable @change="ProjectChange" filterable placeholder="项目" style="margin-right: 10px;">
                  <el-option
                  v-for="item in projectList"
                  :key="item.app_id"
                  :label="item.label"
                  :value="item.app_id">
                      <div>
                          <dl v-html="item.icon">
                          {{item.icon}}
                          </dl>
                          <span style="padding-left:25px">{{ item.app_name }}</span> 
                      </div>                 
                  </el-option>
              </el-select>
              <!-- <el-select v-model="Project" clearable="" @change="ProjectChange($event)" filterable placeholder="项目" style="margin-right: 10px;">
                  <el-option
                  v-for="item in projectList"
                  :key="item"
                  :label="item"
                  :value="item">               
                  </el-option>
              </el-select>           -->
            <!-- <ElSelectCountry style="margin-right: 10px; width: 180px" ref="childCountry" @CountryChange='CountryChange($event)'></ElSelectCountry>
            <ElSelectMedia style="margin-right: 10px; width: 180px" ref="childMedia" @MediachangeValue='MediaChange($event)'></ElSelectMedia> -->
          <el-button
              type="primary"
              plain
              icon="el-icon-search"
              @click="handleSearch"
              style="margin-bottom: 10px; margin-top: 10px; margin-right: 10px"
              :disabled="kaiguan"
              >搜索</el-button
            >
            <el-button v-if="fanhui" plain type="primary" icon="el-icon-search" @click="fanhuiss" style="margin-left: 0px">返回</el-button>
          </div>
        </div>


        <panel-group :cardDataCh="basicCardDataCh" :cardData="basicCardData" />

        <!-- <el-row style="background:#fff;padding:16px 16px 0;margin-bottom:32px;">
        <bar-chart :chart-data="lineChartData" />
        </el-row> -->

        <el-row style="background:#fff;padding:16px 16px 0;margin-bottom:32px;">
        <line-chart :chart-data="lineChartData" />
        </el-row>

        <el-row style="background:#fff;padding:16px 16px 0;margin-bottom:32px;">
            <bar-chart :chart-data="barChartData" />
        </el-row>

        <div class="container">
        <div class="options-bar">
        <span style="16px;color:gray;margin-right:30px;float:left;">快速检索</span>
        <el-input
        v-model="searchQuery"
        placeholder="空格隔开筛选项"
        clearable
        @input="searchInput()"
        style="width:240px;margin-right:10px;"
        ></el-input>
        <el-button
          @click="download"
          style="margin-left: auto"
          icon="el-icon-s-release"
          type="info"
          plain
        >导出csv</el-button>
        </div>

        <el-table
          v-loading="dailyReportLoading"
          element-loading-text="数据正在加载中"
          :data="tableDailySearchList"
          height="550"
          border
          class="table"
          style="width: 100%"
          ref="multipleDailyTable"
          @sort-change="sortChange"
          header-cell-class-name="table-header"
          :header-cell-style="{ color: '#696969' }"
        >
          <ex-table-column
            :min-width="120"
            :autoFit="true"
            :fitHeader="true"
            sortable
            v-for="item in tableDailyColumns1"
            :key="item"
            :prop="item"
            fixed
            :label="item"
          />

          <ex-table-column
            :autoFit="true"
            :fitHeader="true"
            sortable
            v-for="item in tableDailyColumns2"
            :key="item"
            :prop="item"
            :label="item"
          />
        </el-table>
        <pagination
          @size-change="handleSizeDailyChange"
          @current-change="handleCurrentDailyChange" 
          :current-page.sync="currentPage"
          :page-sizes="pageSizes" 
          :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" 
          :total="totalCount"
        ></pagination>
        </div>

        <div class="container" style="margin-top: 32px;">
        <h3>内购订单</h3>
        <!-- <div class="options-bar">
          <span style="16px;color:gray;margin-right:30px;float:left;">快速检索</span>
          <el-input
          v-model="searchQuery"
          placeholder="空格隔开筛选项"
          clearable
          @input="searchInputTwo()"
          style="width:240px;margin-right:10px;"
          ></el-input>
          <el-button
            @click="download"
            style="margin-left: auto"
            icon="el-icon-s-release"
            type="info"
            plain
          >导出csv</el-button>
        </div> -->

        <el-table
        v-loading="pictLoading"
        element-loading-text="数据正在加载中"
        :data="tableIapOrder2"
        height="550"
        border
        class="table"
        ref="multipleTable"
        :header-cell-style="{ color: '#696969' }"
        >
        <af-table-column
          sortable
          v-for="item in tableIapColumns"
          :key="item"
          :prop="item"
          :label="item"
        />
        </el-table>
        <pagination
        @size-change="handleSizeDailyChange2"
        @current-change="handleCurrentDailyChange2" 
        :current-page.sync="currentPage2"
        :page-sizes="pageSizes2" 
        :page-size="pageSize2" layout="total, sizes, prev, pager, next, jumper" 
        :total="totalCount2"
        ></pagination>
          <!-- <el-table
            v-loading="dailyReportLoading"
            element-loading-text="数据正在加载中"
            :data="tableIapOrder"
            height="550"
            border
            class="table"
            style="width: 100%"
            ref="multipleDailyTable"
            @sort-change="sortChange"
            header-cell-class-name="table-header"
            :header-cell-style="{ color: '#696969' }"
          >
            <ex-table-column
              :min-width="120"
              :autoFit="true"
              :fitHeader="true"
              sortable
              v-for="item in tableIapColumns1"
              :key="item"
              :prop="item"
              fixed
              :label="item"
            />

            <ex-table-column
              :autoFit="true"
              :fitHeader="true"
              sortable
              v-for="item in tableIapColumns2"
              :key="item"
              :prop="item"
              :label="item"
            />
        </el-table> -->

        </div>

      </div>
    </div>

  </div>


</template>

<script>
import PanelGroup from '../PanelGroup'
import LineChart from '../LineChart'
import BarChart from '../BarChart'
import Vue from 'vue';
import echarts from 'echarts';
import { fetchData } from '../../api/index';
import json2csv from 'json2csv';
import cw403 from './403.vue';
import ElSearchInput from '../SelectComponent/searchInput.vue';
import ElSelectCountry from '../SelectComponent/new_select_country.vue'
import ElSelectMedia from '../SelectComponent/new_select_media.vue';

var lineChartData = {
  spendData: [],
  installData: [],
  rangeDate:[],
}

var barChartData = {
  barData:[],
  dateRange:[],
  platformData:[]
}

const basicCardData = [
  {'data': '', 'name': '消耗', 'color': '', 'icon': 'ad'}, 
  {'data': '', 'name': '展示', 'color': '', 'icon': 'impressions'}, 
  {'data': '', 'name': '点击', 'color': '', 'icon': 'clicks'}, 
  {'data': '', 'name': '点击至激活', 'color': '', 'icon': 'click_rate'}, 
  {'data': '', 'name': '非自然安装', 'color': '', 'icon': 'peoples'}, 
]

const basicCardDataCh = [
  {'data': '', 'name': '自然安装', 'color': '', 'icon': 'peoples'}, 
  {'data': '', 'name': '非自然量收入', 'color': '', 'icon': 'money'}, 
  {'data': '', 'name': '自然量收入', 'color': '', 'icon': 'money'}
]

export default {
  name: 'DashboardAdmin',
  components: {
    PanelGroup,
    LineChart,
    BarChart,
    cw403,
    ElSelectCountry,
    ElSelectMedia,
    ElSearchInput,
  },
  data() {
    return {
      fieldOrder:'',
      fieldValue:'',
      searchQuery:'',
      breadCrumbData:[],
      tableData: [],
      lineChartData: lineChartData,
      barChartData: barChartData,
      kaiguan: false,
      basicCardData:basicCardData,
      basicCardDataCh:basicCardDataCh,
      projectList:[],
      Project:'',
      projectDict:{},
      countryList:[],
      Country:[],
      user_group: localStorage.getItem('user-group'),
      fanhui: false,
      date: [],
      type: false,
      multipleSelection: [],
      delList: [],
      form: {},
      idx: -1,
      id: -1,
      searchId:'',
      mediaSourceName:'',
      campaignId:'',
      adSetId:'',
      tableColumns: [],
      tableColumns1: [],
      tableColumns2: [],
      tableDailyColumns: [],
      tableDailyColumns1: [],
      tableDailyColumns2: [],
      tableIapColumns: [],
      tableIapColumns1: [],
      tableIapColumns2: [],
      tableSearchList: [],
      tableDailySearchList: [],
      tableIapOrder:[],
      tableIapOrder2:[],
      pictLoading: false,
      dailyReportLoading: false,
      pageInfo: {
        size: 50,
        current: 1,
        total: 0,
      },
      pageInfo2: {
        size: 50,
        current: 1,
        total: 0,
      },
      // 默认显示第几页
      currentPage:1,
      currentPage2:1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount:1,
      totalCount2:1,
      // 个数选择器（可修改）
      pageSizes:[50,100,200,300,400],
      pageSizes2:[50,100,200,300,400],
      // 默认每页显示的条数（可修改）
      pageSize:50,
      pageSize2:50,
    }
  },
  created() {
    // 初始请求，请求项目
    this.getProject();
    this.handleInitSearch();
    // this.getAdsource();
    // this.getCountry();
  },
  methods: {
    ProjectChange(e){
      // console.log(e)
      this.projectList.map((item, i) => {
        if (item.app_id == e) {
          this.projectDict['app_name'] = item.app_name; //传值
          this.projectDict['app_id'] = item.app_id;//传值
          this.projectDict['platform'] = item.platform;//传值
          // console.log(e)
        }
      });
      this.getCountry();
      this.getMedia();

    },
    MediaChange(e){
      // 改变值，请求国家选择框的值
    //   this.getCampaign();
    },
    CountryChange(e){
      // 当改变值的时候，请求下个选项框的值，如果此选择框没有联动的下个选项框就为空就可以
      // console.log('接受');
      // console.log(e);
    },
    //返回按钮
    fanhuiss() {
      this.fanhui = false;
      this.tableData = [];
    },
    // 检索
    SearchInput(tableList){
      // this.tableSearchList = tableList;
      // this.pageInfo.total = tableList.length;
      this.tableDailySearchList = tableList;
      this.totalCount2 = tableList.length;
    },
        // 检索
    SearchInputTwo(tableList){
      // this.tableSearchList = tableList;
      // this.pageInfo.total = tableList.length;
      this.tableIapOrder = tableList;
      this.totalCount2 = tableList.length;
    },
    //导出csv文件
    download() {
      //datas：数据，fields：字段名
      try {
        const result = json2csv.parse(this.tableData);
        // 判断浏览器类型
        if (
          (navigator.userAgent.indexOf('compatible') > -1 && navigator.userAgent.indexOf('MSIE') > -1) ||
          navigator.userAgent.indexOf('Edge') > -1
        ) {
          //IE10或Edge浏览器
          var BOM = '\uFEFF';
          var csvData = new Blob([BOM + result], { type: 'text/csv' });
          navigator.msSaveBlob(csvData, `test.csv`);
        } else {
          //非IE浏览器
          var csvContent = 'data:text/csv;charset=utf-8,\uFEFF' + result;
          //使用a标签的download属性实现下载功能
          var link = document.createElement('a');
          link.href = encodeURI(csvContent);
          link.download = `test.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (err) {
        alert(err);
      }
    },
    // 表格排序
    sortChange (column, prop, order){
      // console.log(column.prop); //prop标签 => 字段
      // console.log(column.order);//descending降序、ascending升序
      this.fieldOrder = column.order;
      this.fieldValue = column.prop;
      if(column.order!=null){
        this.getDailyTable(this.pageSize,this.currentPage);
      }
    },
    // 搜索请求
    searchInput(){
      // console.log('1');
      // console.log(this.query);
      if(this.searchQuery!=''){
        var that = this;
        clearTimeout(that.searchTimer);
        // 延时1s后检索
        that.searchTimer = setTimeout(function () { 
          // console.log("在这里停顿");
          that.getDailyTable(that.pageSize,that.currentPage);
        },1000)
      }
    },
    // 改变显示的条数
    handleSizeChange(val) {
      this.pageInfo.current = 1;
      this.pageInfo.size = val;
    },
    // handleSizeDailyChange(val) {
    //   this.currentPage = 1;
    //   this.pageSize = val;
    // },
    // 每页显示的条数
    handleSizeDailyChange(val) {
        // 改变每页显示的条数 
        this.pageSize=val
        // 点击每页显示的条数时，显示第一页
        this.getDailyTable(val,1)
        // 注意：在改变每页显示的条数时，要将页码显示到第一页
        this.currentPage=1  
    },
    handleSizeDailyChange2(val) {
        // 改变每页显示的条数 
        this.pageSize2=val
        // 点击每页显示的条数时，显示第一页
        // console.log(this.tableIapOrder.splice(0,Math.min(val,this.totalCount2)))
        // this.tableIapOrder2 = this.tableIapOrder.splice(0,1*val)
        // console.log(this.tableIapOrder2)
        // 注意：在改变每页显示的条数时，要将页码显示到第一页
        this.currentPage2=1  
    },
    // 显示第几页
    handleCurrentDailyChange(val) {
        // console.log('改变页数')
        // 改变默认的页数
        this.currentPage=val
        // 切换页码时，要获取每页显示的条数
        // this.handleSearch(this.PageSize,(val)*(this.PageSize))
        this.getDailyTable(this.pageSize,val)
    },
    handleCurrentDailyChange2(val) {
        // console.log('改变页数')
        // 改变默认的页数
        this.currentPage2=val
        console.log(val)
        // 切换页码时，要获取每页显示的条数
        // this.handleSearch(this.PageSize,(val)*(this.PageSize))
        this.tableIapOrder2 = this.tableIapOrder.slice((val-1)*this.pageSize2,Math.min(val*this.pageSize2,this.totalCount2))
    },
    //获取游戏名
    getProject() {
      this.$http
        .get('index/project_data/', {
          headers: {
            Authorization: 'JWT ' + localStorage.getItem('jwt-token')
          }
        })
        .then(function (res) {
          if (res.data.code == 200) {
            this.projectList = res.data.project_data;
            // this.list1.unshift('all');
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    // 获取国家
    getCountry() {
      this.$axios
        .post(
          'index/country_data/',
          {
            date_range: this.date,
            // project: [this.Project],
            project: this.projectDict,
            // project: [this.$refs.childProject.select_project],
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
          this.$refs.childCountry.country_list = res.data.country_data;
          this.$refs.childCountry.originalList = res.data.country_data;
          // this.countryList = res.data.country_data;
        })
        .catch((e) => {});
    },
    // 获取媒体渠道
    getMedia(){
        this.$axios
          .post(
            'index/media_data/',
            {
              // project: [this.Project],
              project: this.projectDict,
              country: this.$refs.childCountry.select_country,
            },
            {
              headers: {
                Authorization: 'JWT ' + localStorage.getItem('jwt-token')
              }
            }
          )
          .then((res) => {
            this.$refs.childMedia.value_list = res.data.media_data;
            this.$refs.childMedia.originalList = res.data.media_data;
          })
        .catch((e) => {});
    },
    // 获取之前的日期的函数
    getBeforeDate(n) {
          let _n = n;
          let _d = new Date();
          let _year = _d.getFullYear();
          let _mon = _d.getMonth() + 1;
          let _day = _d.getDate();
          if(_day <= _n) {
              if(_mon > 1) {
                  _mon = _mon - 1;
              } else {
                  _year = _year - 1;
                  _mon = 12;
              }
          }
          _d.setDate(_d.getDate() - n);
          _year = _d.getFullYear();
          _mon = _d.getMonth() + 1;
          _day = _d.getDate();
          var date_sub = _year + "-" + (_mon < 10 ? ('0' + _mon) : _mon) + "-" + (_day < 10 ? ('0' + _day) : _day);
          return date_sub;
    },
    // 请求每日概况
    getDailyTable(n1,n2) {
      //post请求获取搜索内容
      this.$axios
        .post(
          'index/daily_report/',
          {
            date_range: this.date,
            project: this.projectDict,
            // 排序
            field_order:this.fieldOrder,
            field_value:this.fieldValue,
            // 搜索
            search_query:this.searchQuery,
            // 每页显示的条数
            page_size:n1,
            // 显示第几页
            current_page:n2,
            // project: [this.Project],
            // project: [this.$refs.childProject.select_project],
            // country: this.$refs.childCountry.select_country,
            // media_source: this.$refs.childMedia.select_value,
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
            this.tableData = res.data.list;
            this.totalCount = res.data.total
            // this.totalCount = res.data.list.length;
            // this.totalCount = res.data.res.data.list.length;
            // console.log(111)
            this.tableDailyColumns = [];
            for (var item in res.data.list[0]) {
              this.tableDailyColumns.push(item);
            }
            this.tableDailyColumns1 = this.tableDailyColumns.splice(0, res.data.fix_columns);
            this.tableDailyColumns2 = this.tableDailyColumns;
            this.dailyReportLoading = false;
            this.$nextTick(() => {
              this.$refs.multipleDailyTable.doLayout();
              // el-table加ref="multipleTable"
            });
            this.tableDailySearchList = res.data.list;
            // 搜索需要经过子组件里的检索函数
            // console.log(222)
            // if(type===0){
            //     // 初始化的时候组件无效
            //     this.tableDailySearchList = res.data.list
            // } else {
            // this.$refs.searchInput.searchTable();
            // }
            // this.tableDailySearchList = res.data.list
        })
        .catch((e) => {});
      
    },
    //  请求内购订单
    getIapTable(n1,n2) {
      //post请求获取搜索内容
      this.$axios
        .post(
          'index/iap_report/',
          {
            date_range: this.date,
            project: this.projectDict,
            // // 排序
            // field_order:this.fieldOrder,
            // field_value:this.fieldValue,
            // // 搜索
            // search_query:this.searchQuery,
            // 每页显示的条数
            // page_size:n1,
            // // 显示第几页
            // current_page:n2,
            // project: [this.Project],
            // project: [this.$refs.childProject.select_project],
            // country: this.$refs.childCountry.select_country,
            // media_source: this.$refs.childMedia.select_value,
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
            // this.tableData = res.data.list;
            this.totalCount2 = res.data.total
            // this.totalCount = res.data.list.length;
            // this.totalCount = res.data.res.data.list.length;
            // console.log(111)
            this.tableIapColumns = [];
            for (var item in res.data.list[0]) {
              this.tableIapColumns.push(item);
            }
            // this.tableIapColumns1 = this.tableIapColumns.splice(0, res.data.fix_columns);
            // this.tableIapColumns2 = this.tableIapColumns;
            this.dailyReportLoading = false;
            // this.$nextTick(() => {
            //   this.$refs.multipleDailyTable.doLayout();
            //   // el-table加ref="multipleTable"
            // });
            this.tableIapOrder = res.data.list;
            this.tableIapOrder2 = this.tableIapOrder.slice(0,this.pageSize2)
            // 搜索需要经过子组件里的检索函数
            // console.log(222)
            // if(type===0){
            //     // 初始化的时候组件无效
            //     this.tableDailySearchList = res.data.list
            // } else {
            // this.$refs.searchInput.searchTable();
            // }
            // this.tableDailySearchList = res.data.list
        })
        .catch((e) => {});
      
    },
    
    // 请求table数据
    getTable() {
      // 不会弄初始化load，只能用初始值赋值实现
      // this.basicCardData = basicCardData;
      //post请求获取搜索内容
      this.$axios
        .post(
          'index/table/',
          {
            date_range: this.date,
            project: this.projectDict,
            // project: [this.Project],
            // project: [this.$refs.childProject.select_project],
            country: [],//this.$refs.childCountry.select_country,
            media_source: [], //this.$refs.childMedia.select_value,
            campaign_id: this.campaignId,
            ad_set_id: this.adSetId,
            media_source_name: this.mediaSourceName
            // ad_id: this.adId,
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
            this.tableSearchList = res.data.list;
            this.pageInfo.total = res.data.list.length;
            // this.totalCount = res.data.list.length;
            this.tableColumns = [];
            for (var pro in res.data.list[0]) {
              this.tableColumns.push(pro);
            }
            this.tableColumns1 = this.tableColumns.splice(0, res.data.fix_columns);
            this.tableColumns2 = this.tableColumns;
            this.$nextTick(() => {
              this.$refs.multipleTable.doLayout();
              // el-table加ref="multipleTable"
            });
            this.pictLoading = false;
            // 搜索需要经过子组件里的检索函数
            // this.$refs.searchInput.searchTable();

        })
        .catch((e) => {});
      
    },
    // clickhous数据比较慢，分开请求获取
    getChData(type){
      console.log(1);
      if(type===1){
        var dateRangeCh = this.date;
        var projectCh = this.projectDict;
        var countryCh = []; //this.$refs.childCountry.select_country;
        var mediaSourceCh = []; //this.$refs.childMedia.select_value;
      }
      else{
        var dateRangeCh = [];
        var projectCh = [];
        var countryCh = [];
        var mediaSourceCh = [];
      }
      this.$axios
        .post(
          'index/ch_data/',
          {
            date_range: dateRangeCh,
            project: projectCh,
            // project: [this.Project],
            // project: [this.$refs.childProject.select_project],
            country: countryCh,
            media_source: mediaSourceCh,
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
            // console.log('1')
            // this.basicCardData = res.data.data;
            this.basicCardDataCh = res.data.data.card_data;
        })
        .catch((e) => {});
    },
    // 初始化
    handleInitSearch(){
      this.getChData(0);
      this.getDailyTable(50,1);
      this.getIapTable(50,1);
      // this.getTable();
      this.$axios
        .post(
          'index/info/',
          {
            date_range: [],
            project: [],
            // project: [this.Project],
            // project: [this.$refs.childProject.select_project],
            country: [],
            media_source: [],
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
            // console.log('1')
            // this.basicCardData = res.data.data;
            this.basicCardData = res.data.data.card_data;
            this.lineChartData = res.data.data.line_data;
            this.barChartData = res.data.data.bar_data;

        })
        .catch((e) => {
          console.log(err);
          alert('用户信息失效，请重新登录!')
          this.$router.push('/Login');
        });

    },
    // 触发搜索按钮
    handleSearch() {
      // 不会弄初始化load，只能用初始值赋值实现
      this.basicCardData = basicCardData;
      this.basicCardDataCh = basicCardDataCh;
      this.getDailyTable(this.pageSize,this.currentPage);
      this.getIapTable(this.pageSize,this.currentPage);
      this.getTable();
      this.getChData(1);
      // myChart.showLoading();
      this.fanhui = true;
      //post请求获取搜索内容
      this.$axios
        .post(
          'index/info/',
          {
            date_range: this.date,
            project: this.projectDict,
            // project: [this.Project],
            // project: [this.$refs.childProject.select_project],
            country: [], //this.$refs.childCountry.select_country,
            media_source: [],//this.$refs.childMedia.select_value,
          },
          {
            headers: {
              Authorization: 'JWT ' + localStorage.getItem('jwt-token')
            }
          }
        )
        .then((res) => {
            // console.log('1')
            // this.basicCardData = res.data.data;
            this.basicCardData = res.data.data.card_data;
            this.lineChartData = res.data.data.line_data;
            this.barChartData = res.data.data.bar_data;

        })
        .catch((e) => {});
      
    },
    // 面包屑点击
    breadCrumbSearch(name,id,type){
      if(type=='media_source'){
        this.mediaSourceName = name;
        this.adSetId = '';
        this.campaignId = '';
        this.breadCrumbData = [{name:name,id:id,type:'media_source'}]
      }
      else if(type=='campaign'){
        this.mediaSourceName = '';
        this.adSetId = '';
        this.campaignId = id;
        for(let i = 1; i < this.breadCrumbData.length; i++){
          if(i!=1){
             this.breadCrumbData.splice(i, 1);
          }
        }
        // this.breadCrumbData.push({name:name,id:id,type:'campaign'})
      }
      else if(type=='ad_set'){
        this.mediaSourceName = '';
        this.adSetId = id;
        this.campaignId = '';
        for(let i = 2; i < this.breadCrumbData.length; i++){
          if(i!=2){
             this.breadCrumbData.splice(i, 1);
          }
        }
        // this.breadCrumbData.push({name:name,id:id,type:'ad_set'})
      }
      else{
        this.mediaSourceName = '';
        this.adSetId = '';
        this.campaignId = '';
        this.breadCrumbData = []
      }
      this.getTable();
    },
    // 表格行点击
    openDetails(e){
      // console.log('点了');
      // console.log(e.user_id);
      if(e.media_source!=undefined){
        this.mediaSourceName = e.media_source;
        this.adSetId = '';
        this.campaignId = '';
        this.breadCrumbData = [{name:e.media_source,id:e.media_source,type:'media_source'}]
      }
      else if(e.campaign_id!=undefined){
        this.mediaSourceName='';
        this.campaignId = e.campaign_id;
        this.adSetId='';
        this.breadCrumbData.push({name:e.campaign_name,id:e.campaign_id,type:'campaign'})
      }
      else if(e.ad_set_id!=undefined){
        this.adSetId = e.ad_set_id;
        this.campaignId = '';
        this.mediaSourceName = '';
        this.breadCrumbData.push({name:e.ad_set_name,id:e.ad_set_id,type:'ad_set'})

      }
      else{
        
      }
      this.getTable()
    },
  },
  mounted() {
    let timeStamp = new Date();
    let year = new Date(timeStamp).getFullYear();
    let month = new Date(timeStamp).getMonth() + 1 < 10 ? '0' + (new Date(timeStamp).getMonth() + 1) : new Date(timeStamp).getMonth() + 1;
    let month1 = new Date(timeStamp).getMonth() < 10 ? '0' + new Date(timeStamp).getMonth() : new Date(timeStamp).getMonth();
    let date = new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate();
    // 初始日期间隔
    let date_sub = this.getBeforeDate(7);
    this.date = [date_sub, year + '-' + month + '-' + date];
  },

}
</script>

<style lang="scss" scoped>

.el-tag .el-icon-close {
  float: right;
  margin-top: 4px;
}
.el-tag {
  background: white;
  width: 100%;
}
.el-table .cell,
.el-table th > .cell {
  white-space: nowrap;
  width: fit-content;
}
.el-select__tags {
  flex-wrap: nowrap;
  overflow: hidden;
}
.el-tag.el-tag--info .el-tag__close {
  display: none;
}
.el-tag.el-tag--info {
  border: none;
  background: white;
}
.el-select__tags-text {
  margin-left: 0px;
}
.el-date-editor--daterange.el-input__inner {
  width: 240px;
}
.el-picker-panel {
  margin-left: 240px;
}

.dashboard-editor-container {
  padding: 32px;
  background-color: rgb(240, 242, 245);
  position: relative;

  .github-corner {
    position: absolute;
    top: 0px;
    border: 0;
    right: 0;
  }

  .chart-wrapper {
    background: #fff;
    padding: 16px 16px 0;
    margin-bottom: 32px;
  }
}

@media (max-width:1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
</style>
<style scoped>
.table {
  width: 100%;
  font-size: 14px;
}
</style>